var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"disabled_setting_tooltip\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <option></option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"profile-settings\" class=\"settings-section\" data-name=\"profile\">\n    <div class=\"profile-settings-form\">\n        <div class=\"profile-main-panel inline-block\">\n            <h3 class=\"inline-block\" id=\"user-profile-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":78}}}))
    + "</h3>\n            <div id=\"user_details_section\">\n                <div class=\"full-name-change-container\">\n                    <div class=\"input-group inline-block grid user-name-parent\">\n                        <div class=\"user-name-section inline-block\">\n                            <label for=\"full_name\" class=\"settings-field-label inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":93},"end":{"line":9,"column":106}}}))
    + "</label>\n                            <div class=\"alert-notification full-name-status\"></div>\n                            <div class=\"settings-profile-user-field-hint\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"How your account is displayed in Zulip.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":74},"end":{"line":11,"column":122}}}))
    + "</div>\n                            <div id=\"full_name_input_container\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_name"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":64},"end":{"line":12,"column":139}}})) != null ? stack1 : "")
    + ">\n                                <input id=\"full_name\" name=\"full_name\" class=\"settings_text_input\" type=\"text\" value=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"current_user"), "full_name", {"start":{"line":13,"column":121},"end":{"line":13,"column":143}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_name"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":148},"end":{"line":13,"column":210}}})) != null ? stack1 : "")
    + " maxlength=\"60\" />\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <form class=\"timezone-setting-form\">\n                    <div class=\"input-group grid\">\n                        <label for=\"timezone\" class=\"settings-field-label inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time zone",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":88},"end":{"line":21,"column":106}}}))
    + "</label>\n                        <div class=\"alert-notification timezone-setting-status\"></div>\n                        <div class=\"timezone-input\">\n                            <select name=\"timezone\" id=\"user_timezone\" class=\"bootstrap-focus-style settings_select\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"settings_object"),"timezone"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":27,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"timezones"),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":31,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n                </form>\n\n                <form class=\"custom-profile-fields-form grid\"></form>\n            </div>\n        </div>\n\n        <div class=\"profile-side-panel\">\n            <div class=\"inline-block user-avatar-section\">\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),depth0,{"name":"image_upload_widget","hash":{"image":lookupProperty(lookupProperty(depth0,"current_user"),"avatar_url_medium"),"is_editable_by_current_user":lookupProperty(depth0,"user_can_change_avatar"),"disabled_text":lookupProperty(helpers,"t").call(alias1,"Avatar changes are disabled in this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":34},"end":{"line":47,"column":88}}}),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":46,"column":60}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload new profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":45,"column":64}}}),"widget":"user-avatar"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div id=\"user-avatar-source\">\n                    <a href=\"https://en.gravatar.com/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Avatar from Gravatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":97},"end":{"line":51,"column":126}}}))
    + "</a>\n                </div>\n            </div>\n            <div class=\"user-details\">\n                <div class=\"input-group\">\n                    <span class=\"user-details-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":53},"end":{"line":56,"column":66}}}))
    + ":</span>\n                    <span class=\"user-details-desc\">"
    + alias2(alias4(alias3(depth0, "user_role_text", {"start":{"line":57,"column":54},"end":{"line":57,"column":68}} ), depth0))
    + "</span>\n                </div>\n\n                <div class=\"input-group\">\n                    <span class=\"user-details-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Joined",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":53},"end":{"line":61,"column":68}}}))
    + ": </span>\n                    <span class=\"user-details-desc\">"
    + alias2(alias4(alias3(depth0, "date_joined_text", {"start":{"line":62,"column":54},"end":{"line":62,"column":70}} ), depth0))
    + "</span>\n                </div>\n            </div>\n            <button class=\"button rounded sea-green\" id=\"show_my_user_profile_modal\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":40}}}))
    + "\n                <i class=\"show-user-profile-icon fa fa-external-link\" aria-hidden=\"true\"></i>\n            </button>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});