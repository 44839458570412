var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"/upgrade/\" class=\"upgrade-tip\" target=\"_blank\" rel=\"noopener noreferrer\">\n        "
    + alias2((((helper = lookupProperty(helpers,"upload_quota_string") || container.strict(depth0, "upload_quota_string", {"start":{"line":4,"column":10},"end":{"line":4,"column":29}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"upload_quota_string","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":31}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upgrade for more space.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":64}}}))
    + "\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">\n        "
    + container.escapeExpression((((helper = lookupProperty(helpers,"upload_quota_string") || container.strict(depth0, "upload_quota_string", {"start":{"line":8,"column":10},"end":{"line":8,"column":29}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"upload_quota_string","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":31}}}) : helper)))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"show_upgrade_message"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});