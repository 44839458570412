var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-group-id=\""
    + alias1(container.lambda(container.strict(depth0, "group_id", {"start":{"line":7,"column":37},"end":{"line":7,"column":45}} ), depth0))
    + "\" class=\"undo_soft_removed_potential_subgroup button small rounded white\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":121},"end":{"line":7,"column":133}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-group-id=\""
    + alias1(container.lambda(container.strict(depth0, "group_id", {"start":{"line":9,"column":37},"end":{"line":9,"column":45}} ), depth0))
    + "\" class=\"remove_potential_subgroup button small rounded white\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":110},"end":{"line":9,"column":125}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"panel_user_list\" colspan=\"2\">\n        "
    + ((stack1 = container.invokePartial(require("../user_group_display_only_pill.hbs"),depth0,{"name":"../user_group_display_only_pill","hash":{"strikethrough":lookupProperty(depth0,"soft_removed")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"soft_removed"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"usePartial":true,"useData":true});