var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"profile-field-row movable-row\" data-profile-field-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":2,"column":67},"end":{"line":2,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":65},"end":{"line":2,"column":71}}}) : helper)))
    + "\">\n    <td class=\"profile_field_name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"profile_field_name\">"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":10,"column":43},"end":{"line":10,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":41},"end":{"line":10,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td class=\"profile_field_hint\">\n        <span class=\"profile_field_hint\">"
    + alias4((((helper = lookupProperty(helpers,"hint") || alias1(depth0, "hint", {"start":{"line":13,"column":43},"end":{"line":13,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"hint","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"profile_field_type\">"
    + alias4((((helper = lookupProperty(helpers,"type") || alias1(depth0, "type", {"start":{"line":16,"column":43},"end":{"line":16,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":16,"column":41},"end":{"line":16,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td class=\"display_in_profile_summary_cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"valid_to_display_in_summary"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td class=\"required-cell\">\n        <span class=\"profile-field-required\">\n            <label class=\"checkbox\" for=\"profile-field-required-"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":30,"column":66},"end":{"line":30,"column":68}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":30,"column":64},"end":{"line":30,"column":70}}}) : helper)))
    + "\">\n                <input class=\"required-field-toggle required-checkbox-"
    + alias4((((helper = lookupProperty(helpers,"required") || alias1(depth0, "required", {"start":{"line":31,"column":72},"end":{"line":31,"column":80}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"required","hash":{},"data":data,"loc":{"start":{"line":31,"column":70},"end":{"line":31,"column":82}}}) : helper)))
    + "\" type=\"checkbox\" id=\"profile-field-required-"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":31,"column":129},"end":{"line":31,"column":131}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":127},"end":{"line":31,"column":133}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"required"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":135},"end":{"line":31,"column":177}}})) != null ? stack1 : "")
    + " data-profile-field-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":31,"column":203},"end":{"line":31,"column":205}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":201},"end":{"line":31,"column":207}}}) : helper)))
    + "\"/>\n                <span class=\"rendered-checkbox\"></span>\n            </label>\n        </span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":45,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"move-handle\">\n            <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n            <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n        </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"profile_field_display_in_profile_summary\">\n            <label class=\"checkbox display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"display_in_profile_summary") || alias1(depth0, "display_in_profile_summary", {"start":{"line":21,"column":64},"end":{"line":21,"column":90}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":21,"column":62},"end":{"line":21,"column":92}}}) : helper)))
    + "\" for=\"profile_field_display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":21,"column":142},"end":{"line":21,"column":144}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":21,"column":140},"end":{"line":21,"column":146}}}) : helper)))
    + "\">\n                <input class=\"display_in_profile_summary display_in_profile_summary_checkbox_"
    + alias4((((helper = lookupProperty(helpers,"display_in_profile_summary") || alias1(depth0, "display_in_profile_summary", {"start":{"line":22,"column":95},"end":{"line":22,"column":121}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":22,"column":93},"end":{"line":22,"column":123}}}) : helper)))
    + "\" type=\"checkbox\" id=\"profile_field_display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":22,"column":188},"end":{"line":22,"column":190}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":22,"column":186},"end":{"line":22,"column":192}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"display_in_profile_summary"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":194},"end":{"line":22,"column":254}}})) != null ? stack1 : "")
    + " data-profile-field-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":22,"column":280},"end":{"line":22,"column":282}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":22,"column":278},"end":{"line":22,"column":284}}}) : helper)))
    + "\"/>\n                <span class=\"rendered-checkbox\"></span>\n            </label>\n        </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <button class=\"button rounded small btn-warning open-edit-form-modal tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":126},"end":{"line":38,"column":139}}}))
    + "\" data-profile-field-id=\""
    + alias2((((helper = lookupProperty(helpers,"id") || alias3(depth0, "id", {"start":{"line":38,"column":166},"end":{"line":38,"column":168}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":38,"column":164},"end":{"line":38,"column":170}}}) : helper)))
    + "\">\n            <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n        </button>\n        <button class=\"button rounded small delete btn-danger tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":111},"end":{"line":41,"column":126}}}))
    + "\" data-profile-field-id=\""
    + alias2((((helper = lookupProperty(helpers,"id") || alias3(depth0, "id", {"start":{"line":41,"column":153},"end":{"line":41,"column":155}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":151},"end":{"line":41,"column":157}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"profile_field"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":47,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});