var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"domain\">"
    + alias2((((helper = lookupProperty(helpers,"domain") || container.strict(depth0, "domain", {"start":{"line":3,"column":25},"end":{"line":3,"column":31}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"domain","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":33}}}) : helper)))
    + "</td>\n    <td>\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" class=\"allow-subdomains\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"allow_subdomains"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":64}}})) != null ? stack1 : "")
    + " />\n            <span class=\"rendered-checkbox\"></span>\n        </label>\n    </td>\n    <td><button class=\"button btn-danger small rounded delete_realm_domain\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":76},"end":{"line":11,"column":91}}}))
    + "</button></td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"realm_domain"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "");
},"useData":true});