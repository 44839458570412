var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "    <div class=\"integration-event-wrapper\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" class=\"integration-event\" id=\""
    + alias3(alias2(alias1(depth0, "event_id", {"start":{"line":4,"column":67},"end":{"line":4,"column":80}} ), depth0))
    + "\" checked=true\n              value=\""
    + alias3(alias2(alias1(depth0, "event", {"start":{"line":5,"column":23},"end":{"line":5,"column":33}} ), depth0))
    + "\" />\n            <span class=\"rendered-checkbox\"></span>\n        </label>\n        <label for=\""
    + alias3(alias2(alias1(depth0, "event_id", {"start":{"line":8,"column":22},"end":{"line":8,"column":35}} ), depth0))
    + "\" class=\"inline integration-event-name\">\n            "
    + alias3(alias2(alias1(depth0, "event", {"start":{"line":9,"column":14},"end":{"line":9,"column":24}} ), depth0))
    + "\n        </label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"events"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":9}}})) != null ? stack1 : "");
},"useData":true});