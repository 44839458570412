var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Click on the pencil (<z-pencil-icon></z-pencil-icon>) icon to edit and reschedule a message.\n                        \n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-pencil\"></i>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"scheduled_messages_overlay\" class=\"overlay\" data-overlay=\"scheduled\">\n    <div class=\"flex overlay-content\">\n        <div class=\"overlay-messages-container overlay-container scheduled-messages-container\">\n            <div class=\"overlay-messages-header\">\n                <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":47}}}))
    + "</h1>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n                <div class=\"removed-drafts\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"scheduled-messages-list overlay-messages-list\">\n                <div class=\"no-overlay-messages\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No scheduled messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":50}}}))
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-pencil-icon"],"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":91}}}) || fn;
  return fn;
  }

,"useDecorators":true,"useData":true,"useDepths":true});