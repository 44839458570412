var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert\" id=\"dev_env_msg\"></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            You must <z-link>configure your email</z-link> to access this feature.\n            \n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#configure-email-for-demo-organization-owner\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_subscribe_other_users"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":13,"column":14},"end":{"line":13,"column":45}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":19,"column":4}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"invite-stream-notice\">\n        "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    ";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "The users you invite will be automatically added to <z-link>default channels</z-link> for this organization, as you do not have permission to configure which channels new users join.\n        \n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a target=\"_blank\" rel=\"noopener noreferrer\" href=\"#organization/default-channels-list\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"default"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":60}}})) != null ? stack1 : "")
    + " name=\"expires_in\" value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":40,"column":88},"end":{"line":40,"column":98}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":40,"column":104},"end":{"line":40,"column":120}} ), depth0))
    + "</option>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "selected";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option name=\"custom_time_choice\" class=\"custom_time_choice\" value=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":49,"column":90},"end":{"line":49,"column":99}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":49,"column":105},"end":{"line":49,"column":121}} ), depth0))
    + "</option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option name=\"invite_as\" value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"moderator"), "code", {"start":{"line":63,"column":47},"end":{"line":63,"column":79}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Moderators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":84},"end":{"line":63,"column":103}}}))
    + "</option>\n            <option name=\"invite_as\" value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"admin"), "code", {"start":{"line":64,"column":47},"end":{"line":64,"column":75}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":80},"end":{"line":64,"column":103}}}))
    + "</option>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option name=\"invite_as\" value=\""
    + alias1(container.lambda(container.strict(lookupProperty(lookupProperty(depth0,"invite_as_options"),"owner"), "code", {"start":{"line":67,"column":47},"end":{"line":67,"column":75}} ), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Owners",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":80},"end":{"line":67,"column":95}}}))
    + "</option>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channels they should join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":15},"end":{"line":73,"column":49}}}))
    + "</label>\n        <div id=\"streams_to_add\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_select_default_streams_option"),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":83,"column":19}}})) != null ? stack1 : "")
    + "            <div id=\"invite_streams_container\" class=\"add_streams_container\">\n                <div class=\"pill-container stream_picker\">\n                    <div class=\"input\" contenteditable=\"true\"\n                      data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":40},"end":{"line":87,"column":57}}}))
    + "\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"guest_visible_users_container\" class=\"input-group\" style=\"display: none;\">\n    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"select_default_streams\">\n                <label class=\"checkbox display-block modal-field-label\">\n                    <input type=\"checkbox\" id=\"invite_select_default_streams\" checked=\"checked\" />\n                    <span class=\"rendered-checkbox\"></span>\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Default channels for this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":80,"column":66}}}))
    + "\n                </label>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"invite-user-form\">\n    <div class=\"setup-tips-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"development_environment"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":34}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"input-group\">\n        <div id=\"invite_users_option_tabs_container\"></div>\n        <div id=\"invitee_emails_container\">\n            <label for=\"invitee_emails\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emails (one on each line or comma-separated)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":66},"end":{"line":23,"column":119}}}))
    + "</label>\n            <div class=\"pill-container\">\n                <div class=\"input\" contenteditable=\"true\"></div>\n            </div>\n        </div>\n    </div>\n    <div class=\"input-group\" id=\"receive-invite-acceptance-notification-container\">\n        <label class=\"checkbox display-block\">\n            <input type=\"checkbox\" id=\"receive-invite-acceptance-notification\" checked/>\n            <span class=\"rendered-checkbox\"></span>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send me a direct message when my invitation is accepted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":33,"column":76}}}))
    + "\n        </label>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"expires_in\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitation expires after",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":58},"end":{"line":37,"column":91}}}))
    + "</label>\n        <select id=\"expires_in\" class=\"invite-user-select modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"expires_in_options"),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":41,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <p id=\"expires_on\"></p>\n        <div id=\"custom-invite-expiration-time\" class=\"dependent-settings-block\">\n            <label for=\"expires_in\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":62},"end":{"line":45,"column":82}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"custom-expiration-time\" id=\"custom-expiration-time-input\" class=\"custom-expiration-time inline-block\" value=\"\" maxlength=\"3\"/>\n            <select class=\"custom-expiration-time invite-user-select modal_select bootstrap-focus-style\" id=\"custom-expiration-time-unit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"time_choices"),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":50,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <p id=\"custom_expires_on\"></p>\n        </div>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"invite_as\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users join as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":57},"end":{"line":56,"column":79}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select id=\"invite_as\" class=\"invite-user-select modal_select bootstrap-focus-style\">\n            <option name=\"invite_as\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"guest"), "code", {"start":{"line":60,"column":47},"end":{"line":60,"column":75}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Guests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":80},"end":{"line":60,"column":95}}}))
    + "</option>\n            <option name=\"invite_as\" selected=\"selected\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"member"), "code", {"start":{"line":61,"column":67},"end":{"line":61,"column":96}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":101},"end":{"line":61,"column":117}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":68,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_subscribe_other_users"),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":4},"end":{"line":96,"column":11}}})) != null ? stack1 : "")
    + "</form>\n";
},"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":190}}}) || fn;
  return fn;
  }

,"useDecorators":true,"11_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":152}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});