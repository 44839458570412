var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check checked join_leave_button tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_leave"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":112}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_leave"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":140},"end":{"line":3,"column":253}}})) != null ? stack1 : "")
    + "\">\n            <template id=\"leave-"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":4,"column":34},"end":{"line":4,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":40}}}) : helper)))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":8,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-leave-"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":12,"column":41},"end":{"line":12,"column":45}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":47}}}) : helper)))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M448,71.9c-17.3-13.4-41.5-9.3-54.1,9.1L214,344.2l-99.1-107.3c-14.6-16.6-39.1-17.4-54.7-1.8 c-15.6,15.5-16.4,41.6-1.7,58.1c0,0,120.4,133.6,137.7,147c17.3,13.4,41.5,9.3,54.1-9.1l206.3-301.7 C469.2,110.9,465.3,85.2,448,71.9z\"/>\n            </svg>\n            <div class='join_leave_status'></div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "leave-"
    + container.escapeExpression((((helper = lookupProperty(helpers,"name") || container.strict(depth0, "name", {"start":{"line":3,"column":165},"end":{"line":3,"column":169}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":163},"end":{"line":3,"column":171}}}) : helper)))
    + "-group-tooltip-template";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "cannot-leave-"
    + container.escapeExpression((((helper = lookupProperty(helpers,"name") || container.strict(depth0, "name", {"start":{"line":3,"column":217},"end":{"line":3,"column":221}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":215},"end":{"line":3,"column":223}}}) : helper)))
    + "-group-tooltip-template";
},"8":function(container,depth0,helpers,partials,data) {
    return "                        Leave group {name}\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        You do not have permission to leave this group.\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"check join_leave_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_join"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":83}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_join"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":26,"column":131},"end":{"line":26,"column":241}}})) != null ? stack1 : "")
    + "\">\n            <template id=\"join-"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":27,"column":33},"end":{"line":27,"column":37}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":27,"column":31},"end":{"line":27,"column":39}}}) : helper)))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <template id=\"cannot-join-"
    + alias4((((helper = lookupProperty(helpers,"name") || alias2(depth0, "name", {"start":{"line":35,"column":40},"end":{"line":35,"column":44}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":35,"column":38},"end":{"line":35,"column":46}}}) : helper)))
    + "-group-tooltip-template\">\n                <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </template>\n\n            <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n                <path d=\"M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z\"/>\n            </svg>\n            <div class='join_leave_status'></div>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "join-"
    + container.escapeExpression((((helper = lookupProperty(helpers,"name") || container.strict(depth0, "name", {"start":{"line":26,"column":154},"end":{"line":26,"column":158}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":26,"column":152},"end":{"line":26,"column":160}}}) : helper)))
    + "-group-tooltip-template";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "cannot-join-"
    + container.escapeExpression((((helper = lookupProperty(helpers,"name") || container.strict(depth0, "name", {"start":{"line":26,"column":205},"end":{"line":26,"column":209}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":26,"column":203},"end":{"line":26,"column":211}}}) : helper)))
    + "-group-tooltip-template";
},"17":function(container,depth0,helpers,partials,data) {
    return "                        Join group {name}\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                        You do not have permission to join this group.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group-row\" data-group-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":1,"column":40},"end":{"line":1,"column":42}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":44}}}) : helper)))
    + "\" data-group-name=\""
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":1,"column":65},"end":{"line":1,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":71}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_member"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"group-info-box\">\n        <div class=\"top-bar\">\n            <div class=\"group-name\">"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":51,"column":38},"end":{"line":51,"column":42}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":51,"column":36},"end":{"line":51,"column":44}}}) : helper)))
    + "</div>\n        </div>\n        <div class=\"bottom-bar\">\n            <div class=\"description rendered_markdown\" data-no-description=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"No description.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":76},"end":{"line":54,"column":99}}}))
    + "\">"
    + alias4((((helper = lookupProperty(helpers,"description") || alias1(depth0, "description", {"start":{"line":54,"column":103},"end":{"line":54,"column":114}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":54,"column":101},"end":{"line":54,"column":116}}}) : helper)))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});