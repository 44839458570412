var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"member_list_settings_container\">\n    <h4 class=\"user_group_setting_subsection_title\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":28}}}))
    + "\n    </h4>\n    <div class=\"member_list_settings\">\n        <div class=\"member_list_add float-left\">\n            "
    + ((stack1 = container.invokePartial(require("./add_members_form.hbs"),depth0,{"name":"add_members_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"user_group_subscription_request_result\"></div>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div>\n        <h4 class=\"inline-block user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":69},"end":{"line":13,"column":84}}}))
    + "</h4>\n        <span class=\"member-search float-right\">\n            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":77},"end":{"line":15,"column":100}}}))
    + "\" />\n        </span>\n    </div>\n    <div class=\"member-list-box\">\n        "
    + ((stack1 = container.invokePartial(require("./user_group_members_table.hbs"),depth0,{"name":"user_group_members_table","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});