var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide-streamlist-toggle-visibility";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide-navbar-buttons-visibility";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header\">\n    <nav class=\"header-main\" id=\"top_navbar\">\n        <div class=\"column-left\">\n            <a class=\"left-sidebar-toggle-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"embedded"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":105}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" role=\"button\">\n                <i class=\"fa fa-reorder\" aria-hidden=\"true\"></i>\n                <span class=\"left-sidebar-toggle-unreadcount\">0</span>\n            </a>\n            <a href=\"\" class=\"brand no-style\">\n                <img id=\"realm-navbar-wide-logo\" src=\"\" alt=\"\" class=\"nav-logo no-drag\"/>\n                <img id=\"realm-navbar-icon-logo\" alt=\"\" src=\""
    + alias4((((helper = lookupProperty(helpers,"realm_icon_url") || alias2(depth0, "realm_icon_url", {"start":{"line":10,"column":64},"end":{"line":10,"column":78}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"realm_icon_url","hash":{},"data":data,"loc":{"start":{"line":10,"column":61},"end":{"line":10,"column":81}}}) : helper)))
    + "\" class=\"nav-logo no-drag\"/>\n            </a>\n        </div>\n        <div class=\"column-middle\" id=\"navbar-middle\">\n            <div class=\"column-middle-inner\">\n                <div id=\"streamlist-toggle\" class=\"tippy-zulip-delayed-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"embedded"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":79},"end":{"line":15,"column":135}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\"show-left-sidebar-tooltip-template\">\n                    <a class=\"left-sidebar-toggle-button\" role=\"button\" tabindex=\"0\"><i class=\"fa fa-reorder\" aria-hidden=\"true\"></i>\n                        <span class=\"left-sidebar-toggle-unreadcount\">0</span>\n                    </a>\n                </div>\n                <div class=\"top-navbar-container\">\n                    <div id=\"message_view_header\" class=\"notdisplayed\">\n                    </div>\n                    <div id=\"searchbox\">\n                        <form id=\"searchbox_form\" class=\"navbar-search\">\n                            <div id=\"searchbox-input-container\" class=\"input-append pill-container\">\n                                <i class=\"search_icon zulip-icon zulip-icon-search\"></i>\n                                <div class=\"search-input-and-pills\">\n                                    <div class=\"search-input input input-block-level home-page-input\" id=\"search_query\" type=\"text\" data-placeholder-text=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":155},"end":{"line":28,"column":170}}}))
    + "\"\n                                      autocomplete=\"off\" contenteditable=\"true\"></div>\n                                </div>\n                                <button class=\"search_close_button tippy-zulip-delayed-tooltip\" type=\"button\" id=\"search_exit\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Exit search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":139},"end":{"line":31,"column":159}}}))
    + "\" data-tippy-content=\"Close\"><i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i></button>\n                            </div>\n                        </form>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"column-right\">\n            <div class=\"spectator_login_buttons only-visible-for-spectators\">\n                <a class=\"login_button\">\n                    "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":41,"column":35}}}))
    + "\n                </a>\n            </div>\n            <div id=\"userlist-toggle\" class=\"hidden-for-spectators\">\n                <a id=\"userlist-toggle-button\" role=\"button\" class=\"header-button\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-user-list\"></i>\n                </a>\n            </div>\n            <div id=\"help-menu\">\n                <a class=\"header-button tippy-zulip-delayed-tooltip\" tabindex=\"0\" role=\"button\" data-tooltip-template-id=\"help-menu-tooltip-template\">\n                    <i class=\"zulip-icon zulip-icon-help-bigger\" aria-hidden=\"true\"></i>\n                </a>\n            </div>\n            <div id=\"gear-menu\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"embedded"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":39},"end":{"line":54,"column":92}}})) != null ? stack1 : "")
    + "\">\n                <a id=\"settings-dropdown\" tabindex=\"0\" role=\"button\" class=\"header-button tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"gear-menu-tooltip-template\">\n                    <i class=\"zulip-icon zulip-icon-gear\" aria-hidden=\"true\"></i>\n                </a>\n            </div>\n            <div id=\"personal-menu\" class=\"hidden-for-spectators\">\n                <a class=\"header-button tippy-zulip-delayed-tooltip\" tabindex=\"0\" role=\"button\" data-tooltip-template-id=\"personal-menu-tooltip-template\">\n                    <img class=\"header-button-avatar\" src=\""
    + alias4((((helper = lookupProperty(helpers,"user_avatar") || alias2(depth0, "user_avatar", {"start":{"line":61,"column":61},"end":{"line":61,"column":72}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":61,"column":59},"end":{"line":61,"column":74}}}) : helper)))
    + "\"/>\n                </a>\n            </div>\n            <div class=\"spectator_narrow_login_button only-visible-for-spectators\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":103},"end":{"line":64,"column":118}}}))
    + "\" data-tippy-placement=\"bottom\">\n                <a class=\"header-button login_button\">\n                    <i class=\"zulip-icon zulip-icon-log-in\"></i>\n                </a>\n            </div>\n        </div>\n    </nav>\n</div>\n";
},"useData":true});