var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"tippy-zulip-tooltip\" data-tooltip-template-id=\"mobile-push-notification-tooltip-template\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"7":function(container,depth0,helpers,partials,data) {
    return " prop-element";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n    <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_mobile_checkbox"),lookupProperty(depth0,"is_disabled"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":52}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":158}}})) != null ? stack1 : "")
    + ">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\""
    + alias4((((helper = lookupProperty(helpers,"setting_name") || alias2(depth0, "setting_name", {"start":{"line":4,"column":43},"end":{"line":4,"column":55}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":57}}}) : helper)))
    + "\" id=\""
    + alias4((((helper = lookupProperty(helpers,"prefix") || alias2(depth0, "prefix", {"start":{"line":4,"column":65},"end":{"line":4,"column":71}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"prefix","hash":{},"data":data,"loc":{"start":{"line":4,"column":63},"end":{"line":4,"column":73}}}) : helper)))
    + alias4((((helper = lookupProperty(helpers,"setting_name") || alias2(depth0, "setting_name", {"start":{"line":4,"column":75},"end":{"line":4,"column":87}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":89}}}) : helper)))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":50}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_checked"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":56}}})) != null ? stack1 : "")
    + " data-setting-widget-type=\"boolean\"\n              class=\""
    + alias4((((helper = lookupProperty(helpers,"setting_name") || alias2(depth0, "setting_name", {"start":{"line":7,"column":23},"end":{"line":7,"column":35}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":21},"end":{"line":7,"column":37}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":84}}})) != null ? stack1 : "")
    + "\"/>\n            <span class=\"rendered-checkbox\"></span>\n        </label>\n    </span>\n</td>\n";
},"useData":true});