var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"copy_generated_link_container\">\n    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":24}}}))
    + "</span>\n    <a href=\""
    + alias2((((helper = lookupProperty(helpers,"invite_link") || alias3(depth0, "invite_link", {"start":{"line":3,"column":16},"end":{"line":3,"column":27}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"invite_link","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":30}}}) : helper)))
    + "\" id=\"multiuse_invite_link\">"
    + alias2((((helper = lookupProperty(helpers,"invite_link") || alias3(depth0, "invite_link", {"start":{"line":3,"column":61},"end":{"line":3,"column":72}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"invite_link","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":75}}}) : helper)))
    + "</a>\n\n    <span id=\"copy_generated_invite_link\" class=\"copy-btn\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":79},"end":{"line":5,"column":97}}}))
    + "\" data-tippy-placement=\"top\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":138},"end":{"line":5,"column":156}}}))
    + "\" data-clipboard-text=\""
    + alias2((((helper = lookupProperty(helpers,"invite_link") || alias3(depth0, "invite_link", {"start":{"line":5,"column":182},"end":{"line":5,"column":193}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"invite_link","hash":{},"data":data,"loc":{"start":{"line":5,"column":179},"end":{"line":5,"column":196}}}) : helper)))
    + "\" role=\"button\">\n        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n    </span>\n</div>\n";
},"useData":true});