var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Drafts older than <strong>{draft_lifetime}</strong> days are automatically removed.";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = container.invokePartial(require("./draft.hbs"),depth0,{"name":"draft","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"draft_overlay\" class=\"overlay\" data-overlay=\"drafts\">\n    <div class=\"flex overlay-content\">\n        <div class=\"drafts-container overlay-messages-container overlay-container\">\n            <div class=\"overlay-messages-header\">\n                <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":35}}}))
    + "</h1>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n                <div class=\"header-body\">\n                    <div class=\"removed-drafts-message\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts are not synced to other devices and browsers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":85}}}))
    + "\n                        <br />\n                        "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":121}}})) != null ? stack1 : "")
    + "\n                    </div>\n                    <div class=\"delete-drafts-group\">\n                        <div class=\"delete-selected-drafts-button-container\">\n                            <button class=\"button small rounded delete-selected-drafts-button\" type=\"button\" disabled>\n                                <i class=\"fa fa-trash-o fa-lg\" aria-hidden=\"true\"></i>\n                            </button>\n                        </div>\n                        <button class=\"button small rounded select-drafts-button\" role=\"checkbox\" aria-checked=\"false\">\n                            <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select all drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":34},"end":{"line":22,"column":60}}}))
    + "</span>\n                            <i class=\"fa fa-square-o fa-lg select-state-indicator\" aria-hidden=\"true\"></i>\n                        </button>\n                    </div>\n                </div>\n            </div>\n            <div class=\"drafts-list overlay-messages-list\">\n                <div class=\"no-drafts no-overlay-messages\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No drafts.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":38}}}))
    + "\n                </div>\n\n                <div id=\"drafts-from-conversation\">\n                    <h2>"
    + alias2(container.lambda(container.strict(depth0, "narrow_drafts_header", {"start":{"line":34,"column":26},"end":{"line":34,"column":46}} ), depth0))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"narrow_drafts"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":37,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n                <div id=\"other-drafts\">\n                    <h2 id=\"other-drafts-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":70}}}))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"other_drafts"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":43,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});