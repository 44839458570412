var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"export_row\" id=\"export_"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":2,"column":36},"end":{"line":2,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":40}}}) : helper)))
    + "\">\n    <td>\n        <span class=\"acting_user\">"
    + alias4((((helper = lookupProperty(helpers,"acting_user") || alias1(depth0, "acting_user", {"start":{"line":4,"column":36},"end":{"line":4,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"acting_user","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span>"
    + alias4((((helper = lookupProperty(helpers,"export_type") || alias1(depth0, "export_type", {"start":{"line":7,"column":16},"end":{"line":7,"column":27}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"export_type","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":29}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"export_time\">"
    + alias4((((helper = lookupProperty(helpers,"event_time") || alias1(depth0, "event_time", {"start":{"line":10,"column":36},"end":{"line":10,"column":46}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"event_time","hash":{},"data":data,"loc":{"start":{"line":10,"column":34},"end":{"line":10,"column":48}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td class=\"actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_url\"><a href=\""
    + alias2((((helper = lookupProperty(helpers,"url") || container.strict(depth0, "url", {"start":{"line":14,"column":44},"end":{"line":14,"column":47}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":49}}}) : helper)))
    + "\" download>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Complete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":60},"end":{"line":14,"column":77}}}))
    + "</a></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"time_failed"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":51}}}))
    + ": "
    + alias2((((helper = lookupProperty(helpers,"time_failed") || container.strict(depth0, "time_failed", {"start":{"line":16,"column":55},"end":{"line":16,"column":66}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"time_failed","hash":{},"data":data,"loc":{"start":{"line":16,"column":53},"end":{"line":16,"column":68}}}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"pending"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"export_url_spinner\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"time_deleted"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":8}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"export_status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":52}}}))
    + ": "
    + alias2((((helper = lookupProperty(helpers,"time_deleted") || container.strict(depth0, "time_deleted", {"start":{"line":20,"column":56},"end":{"line":20,"column":68}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"time_deleted","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":70}}}) : helper)))
    + "</span>\n        ";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button rounded small delete btn-danger\" data-export-id=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"id") || container.strict(depth0, "id", {"start":{"line":25,"column":81},"end":{"line":25,"column":83}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":25,"column":79},"end":{"line":25,"column":85}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"realm_export"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":31,"column":9}}})) != null ? stack1 : "");
},"useData":true});