var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"stream-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item\">\n            <a class=\"invite-user-link popover-menu-link\" role=\"menuitem\" tabindex=\"0\">\n            <i class=\"popover-menu-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i>\n            <span class=\"popover-menu-label\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":53}}}))
    + "\n            </span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});