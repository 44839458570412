var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "tabindex=\"-1\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((((helper = lookupProperty(helpers,"default_text") || container.strict(depth0, "default_text", {"start":{"line":2,"column":62},"end":{"line":2,"column":74}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"default_text","hash":{},"data":data,"loc":{"start":{"line":2,"column":60},"end":{"line":2,"column":76}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button id=\""
    + alias4((((helper = lookupProperty(helpers,"widget_name") || alias1(depth0, "widget_name", {"start":{"line":1,"column":14},"end":{"line":1,"column":25}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":27}}}) : helper)))
    + "_widget\" class=\"dropdown-widget-button\" type=\"button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_setting_disabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":123}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"disable_keyboard_focus"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":124},"end":{"line":1,"column":174}}})) != null ? stack1 : "")
    + " name=\""
    + alias4((((helper = lookupProperty(helpers,"widget_name") || alias1(depth0, "widget_name", {"start":{"line":1,"column":183},"end":{"line":1,"column":194}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":181},"end":{"line":1,"column":196}}}) : helper)))
    + "\">\n    <span class=\"dropdown_widget_value\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"default_text"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":83}}})) != null ? stack1 : "")
    + "</span>\n    <i class=\"zulip-icon zulip-icon-chevron-down\"></i>\n</button>\n";
},"useData":true});