var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"demo_organization_add_email_form\">\n    <div class=\"tip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"If you haven't updated your name, it's a good idea to do so before inviting other users to join you!",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":130}}}))
    + "</div>\n    <div class=\"input-group\">\n        <label for=\"demo_organization_add_email\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":89}}}))
    + "</label>\n        <input id=\"demo_organization_add_email\" type=\"text\" name=\"email\" class=\"modal_text_input\" value=\""
    + alias2((((helper = lookupProperty(helpers,"delivery_email") || alias3(depth0, "delivery_email", {"start":{"line":5,"column":107},"end":{"line":5,"column":121}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"delivery_email","hash":{},"data":data,"loc":{"start":{"line":5,"column":105},"end":{"line":5,"column":123}}}) : helper)))
    + "\" autocomplete=\"off\" spellcheck=\"false\" autofocus=\"autofocus\"/>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"demo_organization_update_full_name\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":82},"end":{"line":8,"column":95}}}))
    + "</label>\n        <input id=\"demo_organization_update_full_name\" name=\"full_name\" type=\"text\" class=\"modal_text_input\" value=\""
    + alias2((((helper = lookupProperty(helpers,"full_name") || alias3(depth0, "full_name", {"start":{"line":9,"column":118},"end":{"line":9,"column":127}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":116},"end":{"line":9,"column":129}}}) : helper)))
    + "\" maxlength=\"60\" />\n    </div>\n</form>\n";
},"useData":true});