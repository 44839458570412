var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"button no-style user-status-value\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"emoji"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":22,"column":14},"end":{"line":22,"column":25}} ), depth0))
    + "\n        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"emoji"), "emoji_name", {"start":{"line":16,"column":53},"end":{"line":16,"column":69}} ), depth0))
    + ":</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"emoji"),"url"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":12}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"emoji"), "url", {"start":{"line":18,"column":28},"end":{"line":18,"column":37}} ), depth0))
    + "\" class=\"emoji status-emoji\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"emoji status-emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"emoji"), "emoji_code", {"start":{"line":20,"column":55},"end":{"line":20,"column":71}} ), depth0))
    + "\"></div>\n            ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-status-content-wrapper\">\n    <div class=\"tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":78}}}))
    + "\" data-tippy-placement=\"top\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":119},"end":{"line":2,"column":140}}}))
    + "\" id=\"selected_emoji\">\n        <div class=\"status-emoji-wrapper\" tabindex=\"0\">\n            "
    + ((stack1 = container.invokePartial(require("./status_emoji_selector.hbs"),depth0,{"name":"status_emoji_selector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <input type=\"text\" class=\"user-status modal_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":73},"end":{"line":7,"column":93}}}))
    + "\" maxlength=\"60\"/>\n    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_status_message_button\" disabled=\"disabled\">\n        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n<div class=\"user-status-options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"default_status_messages_and_emoji_info"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});