var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\n    <label class=\"group-setting-label\">"
    + alias4((((helper = lookupProperty(helpers,"label") || alias1(depth0, "label", {"start":{"line":2,"column":41},"end":{"line":2,"column":46}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":48}}}) : helper)))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias4((((helper = lookupProperty(helpers,"setting_name") || alias1(depth0, "setting_name", {"start":{"line":3,"column":68},"end":{"line":3,"column":80}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":82}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\" data-placeholder=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":103}}}))
    + "\"></div>\n    </div>\n</div>\n";
},"useData":true});