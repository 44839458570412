var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"scheduled-message-row overlay-message-row\" data-scheduled-message-id=\""
    + alias3(alias2(alias1(depth0, "scheduled_message_id", {"start":{"line":2,"column":88},"end":{"line":2,"column":108}} ), depth0))
    + "\">\n        <div class=\"scheduled-message-info-box overlay-message-info-box\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_stream"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"message_row"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias2(alias1(depth0, "is_stream", {"start":{"line":34,"column":38},"end":{"line":34,"column":47}} ), depth0),{"name":"is_stream","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":34,"column":35},"end":{"line":34,"column":79}}})) != null ? stack1 : "")
    + "\" role=\"listitem\">\n                <div class=\"messagebox\">\n                    <div class=\"messagebox-content\">\n                        <div class=\"message_top_line\">\n                            <div class=\"overlay_message_controls\">\n                                <i class=\"fa fa-pencil fa-lg restore-overlay-message tippy-zulip-tooltip\" aria-hidden=\"true\" data-tooltip-template-id=\"restore-scheduled-message-tooltip-template\"></i>\n                                <i class=\"fa fa-trash-o fa-lg delete-overlay-message tippy-zulip-tooltip\" aria-hidden=\"true\" data-tooltip-template-id=\"delete-scheduled-message-tooltip-template\"></i>\n                            </div>\n                        </div>\n                        <div class=\"message_content rendered_markdown restore-overlay-message tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"restore-scheduled-message-tooltip-template\">"
    + alias3(lookupProperty(helpers,"rendered_markdown").call(alias4,lookupProperty(depth0,"rendered_content"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":43,"column":193},"end":{"line":43,"column":231}}}))
    + "</div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <div class=\"message_header message_header_stream\">\n                <div class=\"message-header-contents\" style=\"background: "
    + alias3(alias2(alias1(depth0, "recipient_bar_color", {"start":{"line":6,"column":74},"end":{"line":6,"column":93}} ), depth0))
    + ";\">\n                    <div class=\"message_label_clickable stream_label\">\n                        <span class=\"stream-privacy-modified-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":8,"column":69},"end":{"line":8,"column":78}} ), depth0))
    + " stream-privacy filter-icon\"  style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_privacy_icon_color", {"start":{"line":8,"column":126},"end":{"line":8,"column":151}} ), depth0))
    + "\">\n                            "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </span>\n                        "
    + alias3(alias2(alias1(depth0, "stream_name", {"start":{"line":11,"column":26},"end":{"line":11,"column":37}} ), depth0))
    + "\n                    </div>\n                    <span class=\"stream_topic_separator\"><i class=\"zulip-icon zulip-icon-chevron-right\"></i></span>\n                    <span class=\"stream_topic\">\n                        <div class=\"message_label_clickable narrows_by_topic\">\n                            <span>"
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":16,"column":36},"end":{"line":16,"column":41}} ), depth0))
    + "</span>\n                        </div>\n                    </span>\n                    <span class=\"recipient_bar_controls\"></span>\n                    "
    + ((stack1 = container.invokePartial(require("./scheduled_message_stream_pm_common.hbs"),depth0,{"name":"scheduled_message_stream_pm_common","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message_header message_header_private_message\">\n                <div class=\"message-header-contents\">\n                    <div class=\"message_label_clickable stream_label\">\n                        <span class=\"private_message_header_icon\"><i class=\"zulip-icon zulip-icon-user\"></i></span>\n                        <span class=\"private_message_header_name\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You and {recipients}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":66},"end":{"line":28,"column":95}}}))
    + "</span>\n                    </div>\n                    "
    + ((stack1 = container.invokePartial(require("./scheduled_message_stream_pm_common.hbs"),depth0,{"name":"scheduled_message_stream_pm_common","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"scheduled_messages_data"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":49,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});