var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"list-item\" role=\"presentation\" data-unique-id=\""
    + alias3(alias2(alias1(depth0, "unique_id", {"start":{"line":2,"column":64},"end":{"line":2,"column":73}} ), depth0))
    + "\" data-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":2,"column":90},"end":{"line":2,"column":94}} ), depth0))
    + "\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"description"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"dropdown-list-item-common-styles\">\n            <span class=\"dropdown-list-item-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bold_current_selection"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n            <span class=\"dropdown-list-item-description\">\n                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "description", {"start":{"line":18,"column":18},"end":{"line":18,"column":29}} ), depth0))
    + "\n            </span>\n        </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"dropdown-list-bold-selected\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":7,"column":64},"end":{"line":7,"column":68}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_delete_icon"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"dropdown-list-delete\">\n                            <i class=\"fa fa-trash-o dropdown-list-delete-icon\"></i>\n                        </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":14,"column":22},"end":{"line":14,"column":26}} ), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"dropdown-list-item-common-styles\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"stream"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./inline_decorated_stream_name.hbs"),depth0,{"name":"inline_decorated_stream_name","hash":{"show_colored_icon":true,"stream":lookupProperty(depth0,"stream")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_direct_message"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":35,"column":12}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"zulip-icon zulip-icon-users stream-privacy-type-icon\"></i> "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":26,"column":87},"end":{"line":26,"column":91}} ), depth0))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_setting_disabled"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":35,"column":12}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"setting-disabled-option\"><i class=\"setting-disabled-option-icon fa fa-ban\" aria-hidden=\"true\"></i>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":127},"end":{"line":28,"column":143}}}))
    + "</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bold_current_selection"),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":34,"column":23}}})) != null ? stack1 : "")
    + "            ";
},"18":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"dropdown-list-bold-selected\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":31,"column":64},"end":{"line":31,"column":68}} ), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"item"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":39,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});