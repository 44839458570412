var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"attachments-settings\" class=\"settings-section\" data-name=\"uploaded-files\">\n    <div id=\"attachment-stats-holder\"></div>\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your uploads",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":32}}}))
    + "</h3>\n        <input id=\"upload_file_search\" class=\"search filter_text_input\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter uploaded files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":97},"end":{"line":5,"column":127}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter uploads",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":141},"end":{"line":5,"column":164}}}))
    + "\"/>\n    </div>\n    <div class=\"clear-float\"></div>\n    <div class=\"alert\" id=\"delete-upload-status\"></div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"name\" class=\"upload-file-name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"File",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":90},"end":{"line":12,"column":103}}}))
    + "</th>\n                <th class=\"active upload-date\" data-sort=\"numeric\" data-sort-prop=\"create_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date uploaded",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":96},"end":{"line":13,"column":118}}}))
    + "</th>\n                <th class=\"upload-mentioned-in\" data-sort=\"mentioned_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentioned in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":94}}}))
    + "</th>\n                <th class=\"upload-size\" data-sort=\"numeric\" data-sort-prop=\"size\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":82},"end":{"line":15,"column":95}}}))
    + "</th>\n                <th class=\"upload-actions actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":67}}}))
    + "</th>\n            </thead>\n            <tbody data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not uploaded any files.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":31},"end":{"line":18,"column":72}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No uploaded files match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":101},"end":{"line":18,"column":154}}}))
    + "\" id=\"uploaded_files_table\"></tbody>\n        </table>\n    </div>\n    <div id=\"attachments_loading_indicator\"></div>\n</div>\n";
},"useData":true});