var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group can-manage-group-container\">\n    <label class=\"group-setting-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can manage this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":73}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2((((helper = lookupProperty(helpers,"can_manage_group_widget_name") || alias3(depth0, "can_manage_group_widget_name", {"start":{"line":3,"column":68},"end":{"line":3,"column":96}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"can_manage_group_widget_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":98}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n\n<div class=\"input-group can-mention-group-container\">\n    <label class=\"group-setting-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can mention this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":74}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2((((helper = lookupProperty(helpers,"can_mention_group_widget_name") || alias3(depth0, "can_mention_group_widget_name", {"start":{"line":13,"column":68},"end":{"line":13,"column":97}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"can_mention_group_widget_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":66},"end":{"line":13,"column":99}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n\n<div class=\"input-group can-add-members-group-container\">\n    <label class=\"group-setting-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add members to this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":81}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2((((helper = lookupProperty(helpers,"can_add_members_group_widget_name") || alias3(depth0, "can_add_members_group_widget_name", {"start":{"line":23,"column":68},"end":{"line":23,"column":101}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"can_add_members_group_widget_name","hash":{},"data":data,"loc":{"start":{"line":23,"column":66},"end":{"line":23,"column":103}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n\n<div class=\"input-group can-join-group-container\">\n    <label class=\"group-setting-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can join this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":71}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2((((helper = lookupProperty(helpers,"can_join_group_widget_name") || alias3(depth0, "can_join_group_widget_name", {"start":{"line":33,"column":68},"end":{"line":33,"column":94}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"can_join_group_widget_name","hash":{},"data":data,"loc":{"start":{"line":33,"column":66},"end":{"line":33,"column":96}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n\n<div class=\"input-group can-leave-group-container\">\n    <label class=\"group-setting-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can leave this group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":39},"end":{"line":42,"column":72}}}))
    + "</label>\n    <div class=\"pill-container person_picker prop-element\" id=\"id_"
    + alias2((((helper = lookupProperty(helpers,"can_leave_group_widget_name") || alias3(depth0, "can_leave_group_widget_name", {"start":{"line":43,"column":68},"end":{"line":43,"column":95}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"can_leave_group_widget_name","hash":{},"data":data,"loc":{"start":{"line":43,"column":66},"end":{"line":43,"column":97}}}) : helper)))
    + "\" data-setting-widget-type=\"group-setting-type\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add roles, groups or users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":45,"column":63}}}))
    + "\"></div>\n    </div>\n</div>\n";
},"useData":true});