var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\" id=\"integration-url-"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":1,"column":47},"end":{"line":1,"column":50}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":52}}}) : helper)))
    + "-container\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" id=\"integration-url-"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":3,"column":53},"end":{"line":3,"column":56}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":3,"column":51},"end":{"line":3,"column":58}}}) : helper)))
    + "-checkbox\" class=\"integration-url-parameter\" />\n        <span class=\"rendered-checkbox\"></span>\n    </label>\n    <label class=\"inline\" for=\"integration-url-"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(depth0, "key", {"start":{"line":6,"column":49},"end":{"line":6,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":54}}}) : helper)))
    + "-checkbox\">"
    + alias4((((helper = lookupProperty(helpers,"label") || alias1(depth0, "label", {"start":{"line":6,"column":67},"end":{"line":6,"column":72}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":65},"end":{"line":6,"column":74}}}) : helper)))
    + "</label>\n</div>\n";
},"useData":true});