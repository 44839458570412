var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"message-history-overlay\" class=\"overlay\" data-overlay=\"message_edit_history\">\n    <div class=\"flex overlay-content\">\n        <div class=\"message-edit-history-container overlay-messages-container overlay-container\">\n            <div class=\"overlay-messages-header\">\n                <h1>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Message edit history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":49}}}))
    + "</h1>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"message-edit-history-list overlay-messages-list\">\n            </div>\n            <div class=\"loading_indicator\"></div>\n            <div id=\"message-history-error\" class=\"alert\">\n            </div>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});