var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"subscriber_list_settings_container\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_access_subscribers"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":114}}})) != null ? stack1 : "")
    + ">\n    <h4 class=\"stream_setting_subsection_title\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":32}}}))
    + "\n    </h4>\n    <div class=\"subscriber_list_settings\">\n        <div class=\"subscriber_list_add float-left\">\n            "
    + ((stack1 = container.invokePartial(require("./add_subscribers_form.hbs"),depth0,{"name":"add_subscribers_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"stream_subscription_request_result\"></div>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div>\n        <h4 class=\"inline-block stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":65},"end":{"line":14,"column":84}}}))
    + "</h4>\n        <span class=\"subscriber-search float-right\">\n            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":77},"end":{"line":16,"column":104}}}))
    + "\" />\n        </span>\n    </div>\n    <div class=\"subscriber-list-box\">\n        "
    + ((stack1 = container.invokePartial(require("./stream_members_table.hbs"),depth0,{"name":"stream_members_table","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda(container.strict(depth0, "render_subscribers", {"start":{"line":1,"column":3},"end":{"line":1,"column":21}} ), depth0),{"name":"render_subscribers","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":23,"column":23}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});