var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"input-group\">\n        <button class=\"button rounded generate_url_for_integration\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Generate URL for an integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":48,"column":52}}}))
    + "\n        </button>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button rounded btn-danger deactivate_bot_button\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":55,"column":35}}}))
    + "\n        </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span>\n            <button class=\"button rounded reactivate_user_button\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":39}}}))
    + "\n            </button>\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bot-edit-form\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":1,"column":40},"end":{"line":1,"column":47}} ), depth0))
    + "\" data-email=\""
    + alias3(alias2(alias1(depth0, "email", {"start":{"line":1,"column":65},"end":{"line":1,"column":70}} ), depth0))
    + "\">\n    <form class=\"edit_bot_form name-setting\">\n        <div class=\"alert\" id=\"bot-edit-form-error\"></div>\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit_bot_full_name\" class=\"modal-field-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":70},"end":{"line":5,"column":83}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"full_name\" id=\"edit_bot_full_name\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "full_name", {"start":{"line":6,"column":126},"end":{"line":6,"column":135}} ), depth0))
    + "\" />\n        </div>\n        <input type=\"hidden\" name=\"is_full_name\" value=\"true\" />\n        <div class=\"input-group email_change_container\">\n            <label for=\"email\" class=\"modal-field-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":57},"end":{"line":10,"column":71}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"email\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "email", {"start":{"line":11,"column":98},"end":{"line":11,"column":103}} ), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group user_id_container\">\n            <label for=\"user_id\" class=\"modal-field-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":59},"end":{"line":14,"column":75}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"user_id\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":15,"column":100},"end":{"line":15,"column":107}} ), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"bot-role-select\" class=\"modal-field-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":67},"end":{"line":18,"column":80}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"bot-role-select\" id=\"bot-role-select\" class=\"modal_select bootstrap-focus-style\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"disable_role_dropdown"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":141},"end":{"line":21,"column":185}}})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"user_role_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"label":lookupProperty(helpers,"t").call(alias4,"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":27}}}),"widget_name":"edit_bot_owner"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div id=\"service_data\">\n        </div>\n        <div class=\"input-group edit-avatar-section\">\n            <label class=\"modal-field-label\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":45},"end":{"line":32,"column":60}}}))
    + "</label>\n            <img src=\""
    + alias3(alias2(alias1(depth0, "bot_avatar_url", {"start":{"line":34,"column":24},"end":{"line":34,"column":38}} ), depth0))
    + "\" id=\"current_bot_avatar_image\" />\n            <input type=\"file\" name=\"bot_avatar_file_input\" class=\"notvisible edit_bot_avatar_file_input\" value=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Upload profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":113},"end":{"line":35,"column":144}}}))
    + "\" />\n            <div class=\"edit_bot_avatar_file\"></div>\n            <div class=\"edit_bot_avatar_preview_text\">\n                <img class=\"edit_bot_avatar_preview_image\" />\n            </div>\n            <button type=\"button\" class=\"button white rounded edit_bot_avatar_upload_button\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Change avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":93},"end":{"line":40,"column":115}}}))
    + "</button>\n            <button type=\"button\" class=\"button white rounded edit_bot_avatar_clear_button\" style=\"display: none;\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Clear profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":115},"end":{"line":41,"column":145}}}))
    + "</button>\n            <div><label for=\"edit_bot_avatar_file\" generated=\"true\" class=\"edit_bot_avatar_error text-error\"></label></div>\n        </div>\n    </form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_incoming_webhook_bot"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":51,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});